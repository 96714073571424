import { fuego } from '@nandorojo/swr-firestore';
import cn from 'classnames';
import firebase from 'firebase/app';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from '../../utils/use-params';
import ChatCompose from './ChatCompose';
import ChatMessage from './ChatMessage';

const MAX_MESSAGES = 150;

const useMessages = (messages, setMessages, fromDate) => {
  const { eventId } = useParams();

  const dbRef = useRef(fuego.db.collection('events').doc(eventId).collection('chat'));

  const add = (message) => {
    dbRef.current.add(message);
  };

  const onSnapshot = useCallback(
    (docs) => {
      const toAppend = [];
      docs.forEach((d) => {
        const data = d.data();
        if (data.date) {
          toAppend.push({ ...data, id: d.id });
        }
      });
      if (toAppend.length > 0) {
        setMessages((currentMessages) => {
          const newMessages = [
            ...currentMessages,
            ...toAppend.filter((m) => !currentMessages.find((n) => n.id === m.id)),
          ];
          return newMessages.slice(
            Math.max(newMessages.length - MAX_MESSAGES, 0),
            newMessages.length
          );
        });
      }
    },
    [setMessages]
  );

  const lastDate =
    !messages[messages.length - 1] ||
    fromDate > messages[messages.length - 1]?.date.toDate()
      ? fromDate
      : messages[messages.length - 1].date;
  useEffect(() => {
    if (!fromDate) return;
    return dbRef.current
      .orderBy('date', 'asc')
      .startAfter(lastDate)
      .onSnapshot(onSnapshot);
  }, [lastDate, fromDate, onSnapshot]);

  return { add };
};

function Chat({ setSidebarOpen, withClose, user, messages, setMessages, listenFrom }) {
  const [lastMessage, setLastMessage] = useState();
  const { add } = useMessages(messages, setMessages, listenFrom);

  useEffect(() => {
    if (lastMessage) lastMessage.scrollIntoView();
  }, [lastMessage]);

  const sendMessage = (message) => {
    const from = user.nickname || user.firstName;
    add({ date: firebase.firestore.FieldValue.serverTimestamp(), from, message });
  };

  return (
    <div className={cn('flex flex-col w-full h-full overscroll-y-contain')}>
      <ul className="flex-grow px-3 py-1 overflow-y-auto">
        {messages.map((m, i) => (
          <ChatMessage
            key={m.id}
            {...m}
            messageRef={i === messages?.length - 1 ? setLastMessage : undefined}
          />
        ))}
      </ul>
      <ChatCompose sendMessage={sendMessage} />
    </div>
  );
}

export default Chat;
