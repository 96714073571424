import cn from 'classnames';
import Button from 'components/common/Button';
import {
  ExitFullscreenIcon,
  FullScreenIcon,
  MuteIcon,
  PauseIcon,
  PlayIcon,
  VolumeIcon,
} from 'components/icons/Icons';
import { useEffect, useState } from 'react';
import screenfull from 'screenfull';
import { useEvent } from 'utils/event-context';

const CastIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 24 24" fill="currentColor">
    <path d="M 4 3 C 2.9069372 3 2 3.9069372 2 5 L 2 8 L 4 8 L 4 5 L 20 5 L 20 19 L 15 19 L 15 21 L 20 21 C 21.093063 21 22 20.093063 22 19 L 22 5 C 22 3.9069372 21.093063 3 20 3 L 4 3 z M 2 10 L 2 12 C 6.9825932 12 11 16.017407 11 21 L 13 21 C 13 14.936593 8.0634068 10 2 10 z M 2 14 L 2 16 C 4.7736661 16 7 18.226334 7 21 L 9 21 C 9 17.145666 5.8543339 14 2 14 z M 2 18 L 2 21 L 5 21 C 5 19.343 3.657 18 2 18 z"></path>
  </svg>
);

function PlayerControls({
  currentStream,
  setCurrentStream,
  playerRef,
  playerStatus,
  showing,
  onCast,
  isConnect,
  onPlay,
  onPause,
  onMute,
  onVolume,
}) {
  const { brandedMode, videos = [] } = useEvent();
  const [fullscreen, setFullscreen] = useState();
  useEffect(() => {
    screenfull?.on?.('change', () => {
      setFullscreen(screenfull?.isFullscreen);
    });
    return () => screenfull?.off?.('change');
  }, []);
  return (
    <div
      className={cn(
        'absolute bottom-0 h-1/3 text-sm w-full flex flex-col justify-end  action-bar-background z-50',
        showing ? 'block' : 'hidden'
      )}
    >
      <div className="flex items-center justify-between w-full h-16 p-3 text-white">
        <div className="flex items-center space-x-3">
          <Button
            Icon={playerStatus.playing ? PauseIcon : PlayIcon}
            onClick={() => (playerStatus.playing ? onPause() : onPlay())}
          />
          <Button
            Icon={playerStatus.muted ? MuteIcon : VolumeIcon}
            onClick={() => onMute()}
          />
          <input
            type="range"
            min="0"
            max="100"
            className="w-12 h-1 bg-gray-500 rounded outline-none appearance-none md:w-24 slider-thumb"
            value={playerStatus.volume * 100}
            onChange={(e) => onVolume(e.target.value / 100)}
            id="myRange"
          />
        </div>
        <div className="flex items-center space-x-3">
          {!brandedMode?.enabled && (
            <div>
              {videos.length > 1 && (
                <select
                  className="px-3 py-1 mx-3 text-sm text-white bg-transparent border-solid rounded-md outline-none"
                  onChange={(e) => setCurrentStream(e.target.value)}
                  onBlur={(e) => setCurrentStream(e.target.value)}
                  value={currentStream}
                >
                  {videos.map(({ uuid, streamName }) => (
                    <option key={uuid} value={uuid}>
                      {streamName}
                    </option>
                  ))}
                </select>
              )}
            </div>
          )}
          {onCast && (
            <Button
              className={isConnect ? 'text-blue-500' : 'text-white'}
              Icon={CastIcon}
              onClick={onCast}
            />
          )}
          <Button
            Icon={fullscreen ? ExitFullscreenIcon : FullScreenIcon}
            onClick={() => screenfull?.isEnabled && screenfull?.toggle(playerRef.current)}
          />
        </div>
      </div>
    </div>
  );
}

export default PlayerControls;
