import { fuego } from '@nandorojo/swr-firestore';
import { useState } from 'react';
import { useEvent } from 'utils/event-context';
import { useParams } from 'utils/use-params';
import EventBackground from './EventBackground';
import Links from './Links';

const login = async (data) => {
  const r = await fetch(`/api/login`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
  const res = await r.json();
  if (r.ok) {
    await fuego.auth().signInWithCustomToken(res.token);
    return { uid: res.uid, sessionId: res.sessionId };
  } else {
    return Promise.reject(res);
  }
};

const LoginForm = ({ onLogin, onError, message }) => {
  const event = useEvent();
  const { eventId } = useParams();
  const [userCode, setUserCode] = useState('');
  const [nickname, setNickname] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    login({ eventId, userCode, nickname })
      .then((data) => onLogin(data))
      .catch(() => {
        setIsLoading(false);
        onError();
      });
  };

  return (
    <EventBackground>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mb-6 text-2xl font-extrabold leading-9 text-center text-gray-900">
          {event.name}
        </h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label
            htmlFor="code"
            className="block text-sm font-medium leading-5 text-gray-700"
          >
            Código de acceso
          </label>
          <div className="mt-1 rounded-md shadow-sm">
            <input
              id="code"
              type="text"
              required
              className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-blue-300 focus:border-blue-300 sm:text-sm sm:leading-5"
              value={userCode}
              onChange={(e) => setUserCode(e.target.value.toUpperCase())}
            />
          </div>
        </div>
        <div className="mt-4">
          <label
            htmlFor="nickname"
            className="block text-sm font-medium leading-5 text-gray-700"
          >
            Ingresa tu nombre
          </label>
          <div className="mt-1 rounded-md shadow-sm">
            <input
              id="nickname"
              type="text"
              required
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-blue-300 focus:border-blue-300 sm:text-sm sm:leading-5"
            />
          </div>
        </div>
        {message && (
          <div className="p-4 mt-5 rounded-md bg-red-50">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="w-5 h-5 text-red-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium leading-5 text-red-800">
                  {message.title}
                </h3>
                <div className="mt-2 text-sm leading-5 text-red-700">
                  {message.description}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="mt-6">
          <span className="block w-full rounded-md shadow-sm">
            <button
              type="submit"
              disabled={!userCode || !nickname || isLoading}
              className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md selected-none hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo-300 active:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? 'Ingresando...' : 'Ingresar al evento'}
            </button>
          </span>
        </div>
      </form>
      <Links />
    </EventBackground>
  );
};

export default LoginForm;
