import { fuego, useDocument } from '@nandorojo/swr-firestore';
import cn from 'classnames';
import {
  ChatIcon,
  DotIcon,
  PlayIcon,
  ShoppingIcon,
  SpeakerphoneIcon,
} from 'components/icons/Icons';
import Loader from 'components/Loader';
import PlayerControls from 'components/player/PlayerControls';
import Sidebar from 'components/sidebar/Sidebar';
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useCast, useMedia } from 'react-chromecast';
import ReactPlayer from 'react-player';
import { cache } from 'swr';
import { useEvent } from 'utils/event-context';
import { isMediaFile } from 'utils/is-media-file';
import { useParams } from 'utils/use-params';
import useUser from 'utils/use-user';

const logout = (userUid) => {
  if (fuego.auth().currentUser?.uid === userUid) fuego.auth().signOut();
};

const useUserDoc = (uid) => {
  const { eventId } = useParams();
  const url = uid ? `events/${eventId}/users/${uid}` : null;
  const { data } = useDocument(url, { listen: true });
  return data;
};

const useTabs = ({ chat, sponsors, shopping } = {}) => {
  const chatEnabled = chat?.enabled;
  const sponsorsEnabled = sponsors?.enabled && sponsors?.content;
  const shoppingEnabled = shopping?.enabled && shopping?.url;

  return useMemo(() => {
    return [
      chatEnabled && { id: 'chat', icon: ChatIcon },
      sponsorsEnabled && { id: 'sponsors', icon: SpeakerphoneIcon },
      shoppingEnabled && { id: 'shopping', icon: ShoppingIcon },
    ].filter(Boolean);
  }, [chatEnabled, shoppingEnabled, sponsorsEnabled]);
};

const useMouseMove = (container) => {
  const timeout = useRef();
  const [mouseMoving, setMouseMoving] = useState(false);

  useEffect(() => {
    const listener = (event) => {
      if (container.current && container.current.contains(event.target)) {
        setMouseMoving(true);
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => setMouseMoving(false), 2000);
      }
    };

    document.addEventListener('mousemove', listener);
    return () => {
      document.removeEventListener('mousemove', listener);
      clearTimeout(timeout.current);
    };
  }, [container]);

  return mouseMoving;
};

const CONFIG = {
  youtube: {
    playerVars: { modestbranding: 1, autoplay: 1, iv_load_policy: 3, showinfo: 0 },
  },
  vimeo: { playerOptions: { autoplay: true, title: false, playsinline: true } },
};

const useChromecast = (URL) => {
  const [castStatus, setCastStatus] = useReducer((s, a) => ({ ...s, ...a }), {
    muted: false,
    volume: 1,
    playing: true,
  });

  const { playMedia, play, pause } = useMedia();
  const { isConnect, castReceiver, handleConnection } = useCast({
    initialize_media_player: 'DEFAULT_MEDIA_RECEIVER_APP_ID',
    auto_initialize: true,
  });

  const onCast = useCallback(async () => {
    if (castReceiver) {
      await handleConnection();
    }
  }, [castReceiver, handleConnection]);

  const [media, setMedia] = useState();
  useEffect(() => {
    return () => media?.stop?.();
  }, [media]);

  useEffect(() => {
    if (isConnect) {
      playMedia(URL).then((m) => {
        setMedia(m);
        setCastStatus({
          muted: m.volume.muted,
          volume: m.volume.level,
          playing: true,
        });
      });
    }
  }, [URL, isConnect, playMedia]);

  return {
    castStatus,
    isConnect,
    onCast: castReceiver && isMediaFile(URL) ? onCast : null,
    play: () => {
      play();
      setCastStatus({ playing: true });
    },
    pause: () => {
      pause();
      setCastStatus({ playing: false });
    },
    mute: () => {
      media.setVolume(
        new castReceiver.media.VolumeRequest(
          new castReceiver.Volume(castStatus.volume, !castStatus.muted)
        )
      );
      setCastStatus({ muted: !castStatus.muted });
    },
    volume: (vol) => {
      media.setVolume(
        new castReceiver.media.VolumeRequest(new castReceiver.Volume(vol, false))
      );
      setCastStatus({ volume: vol, muted: false });
    },
  };
};

const UserCode = ({ user }) => {
  const { showCode } = useEvent();
  if (!showCode?.enabled) return null;

  return (
    <div
      style={{
        top: `${showCode?.y ?? 0}%`,
        right: `${showCode?.x ?? 0}%`,
        fontSize: `${showCode?.fontSize ?? 8}px`,
      }}
      className="absolute z-50 p-1 text-gray-300 bg-black bg-opacity-25 rounded-sm"
    >
      v.{user?.uid}
    </div>
  );
};

const Live = () => (
  <div className="absolute z-30 flex items-center px-1 space-x-1 text-xs font-medium text-white bg-red-500 rounded-sm top-3 left-3">
    <DotIcon className="w-2 h-2" /> <span>LIVE</span>
  </div>
);

const PlayButton = ({ onClick }) => (
  <div className="absolute flex items-center justify-center w-full h-full text-white bg-black bg-opacity-25 z-2">
    <button
      className="p-2 rounded-md hover:bg-gray-200 hover:bg-opacity-25 focus:outline-none"
      onClick={onClick}
    >
      <PlayIcon className="w-24 h-24" />
    </button>
  </div>
);

const Player = ({ user, currentStream, setCurrentStream }) => {
  const event = useEvent();
  const [playerStatus, setPlayerStatus] = useReducer((s, a) => ({ ...s, ...a }), {
    muted: false,
    volume: 1,
    playing: false,
  });

  const playerDiv = useRef();
  const mouseMoving = useMouseMove(playerDiv);
  const showActionBar = !playerStatus.playing || mouseMoving;

  const URL = event?.videos?.find((v) => v.uuid === currentStream)?.link;

  const { castStatus, isConnect, play, pause, mute, volume, onCast } = useChromecast(URL);

  return (
    <div
      className="relative w-full bg-black sm:h-full pb-16/9 sm:pb-0"
      ref={playerDiv}
      style={{ cursor: showActionBar ? 'auto' : 'none' }}
    >
      <UserCode user={user} />
      {event.live && <Live />}

      <ReactPlayer
        className="absolute top-0 bottom-0 object-cover"
        url={!isConnect ? URL : null}
        width="100%"
        height="100%"
        {...playerStatus}
        controls={event.withNativePlayer}
        onPlay={() => setPlayerStatus({ playing: true })}
        onPause={() => setPlayerStatus({ playing: false })}
        style={{ pointerEvents: event.withNativePlayer ? 'auto' : 'none' }}
        config={CONFIG}
      />
      {!isConnect && !playerStatus.playing && !event.withNativePlayer && (
        <PlayButton onClick={() => setPlayerStatus({ playing: true })} />
      )}
      {!event.withNativePlayer && (
        <PlayerControls
          currentStream={currentStream}
          setCurrentStream={setCurrentStream}
          playerRef={playerDiv}
          playerStatus={isConnect ? castStatus : playerStatus}
          onPlay={() => (isConnect ? play() : setPlayerStatus({ playing: true }))}
          onPause={() => (isConnect ? pause() : setPlayerStatus({ playing: false }))}
          onMute={() =>
            isConnect ? mute() : setPlayerStatus({ muted: !playerStatus.muted })
          }
          onVolume={(vol) =>
            isConnect ? volume(vol) : setPlayerStatus({ volume: vol, muted: false })
          }
          showing={showActionBar}
          onCast={onCast}
          isConnect={isConnect}
        />
      )}
    </div>
  );
};

const EventPlayer = ({ loginData, onLogout }) => {
  const event = useEvent();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const user = useUser();
  const userDoc = useUserDoc(user?.uid);
  const tabs = useTabs(event);

  const [currentStream, setCurrentStream] = useState(undefined);
  useEffect(() => {
    if (currentStream === undefined && (event?.videos?.length ?? 0) > 0) {
      setCurrentStream(event.videos[0].uuid);
    }
  }, [currentStream, event]);

  const requiresLogout =
    userDoc?.sessionId &&
    loginData?.sessionId &&
    userDoc.sessionId !== loginData.sessionId;
  const loginDataUid = loginData?.uid;
  useEffect(() => {
    if (requiresLogout) {
      logout(loginDataUid);
      onLogout();
      cache.clear();
    }
  }, [loginDataUid, onLogout, requiresLogout]);

  if (requiresLogout) return <Loader />;

  const videos = event?.videos ?? [];

  return (
    <div className="relative flex flex-col w-full h-screen subpixel-antialiased sm:flex-row">
      {!event.brandedMode?.enabled ? (
        <div className="sm:h-full sm:flex-grow">
          <Player
            user={userDoc}
            currentStream={currentStream}
            setCurrentStream={setCurrentStream}
          />
        </div>
      ) : (
        <div
          className="bg-center bg-cover sm:flex sm:flex-col sm:flex-grow sm:h-full"
          style={{ backgroundImage: `url(${event.brandedMode.background})` }}
        >
          <div>
            <img
              alt="logo"
              className="h-10 px-4 sm:h-16 sm:py-2"
              src={event.brandedMode.logo}
            />
          </div>
          <Player
            user={userDoc}
            currentStream={currentStream}
            setCurrentStream={setCurrentStream}
          />
          {videos.length > 1 && (
            <ul className="flex justify-center p-1 space-x-4 sm:p-5">
              {videos.map(({ uuid, streamName }) => {
                return (
                  <li key={uuid}>
                    <button
                      onClick={
                        currentStream !== uuid ? () => setCurrentStream(uuid) : null
                      }
                      className={cn(
                        'inline-flex items-center px-2 sm:px-3 py-1 sm:py-2 text-xs sm:text-sm font-medium leading-4 border border-transparent rounded sm:rounded-md focus:outline-none',
                        currentStream === uuid
                          ? 'text-gray-50 bg-purple-600'
                          : 'text-purple-700 bg-purple-100'
                      )}
                    >
                      {streamName}
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
          <div className="max-w-screen-xl p-2 mx-auto">
            <p
              className="text-center"
              style={{ fontSize: '0.5rem' }}
              dangerouslySetInnerHTML={{ __html: event.brandedMode?.disclaimer }}
            ></p>
          </div>
        </div>
      )}

      {!!tabs.length && (
        <Sidebar
          open={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          userDoc={userDoc}
          tabs={tabs}
        />
      )}
    </div>
  );
};

export default EventPlayer;
