const Loader = ({ children = 'Cargando...' }) => {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen space-y-8">
      <div className="relative">
        <span className="flex w-8 h-8">
          <span className="absolute inline-flex w-full h-full bg-purple-400 rounded-full opacity-75 animate-ping"></span>
          <span className="relative inline-flex w-8 h-8 bg-purple-500 rounded-full"></span>
        </span>
      </div>
      <span>{children}</span>
    </div>
  );
};

export default Loader;
