const AUDIO_EXTENSIONS = /\.(m4a|mp4a|mpga|mp2|mp2a|mp3|m2a|m3a|wav|weba|aac|oga|spx)($|\?)/i;
const VIDEO_EXTENSIONS = /\.(mp4|og[gv]|webm|mov|m4v)($|\?)/i;
const HLS_EXTENSIONS = /\.(m3u8)($|\?)/i;
const DASH_EXTENSIONS = /\.(mpd)($|\?)/i;
const FLV_EXTENSIONS = /\.(flv)($|\?)/i;

const isMediaStream = (url) =>
  typeof window !== 'undefined' &&
  typeof window.MediaStream !== 'undefined' &&
  url instanceof window.MediaStream;

const isBlobUrl = (url) => /^blob:/.test(url);

export const isMediaFile = (url) => {
  if (url instanceof Array) {
    for (const item of url) {
      if (typeof item === 'string' && isMediaFile(item)) {
        return true;
      }
      if (isMediaFile(item.src)) {
        return true;
      }
    }
    return false;
  }
  if (isMediaStream(url) || isBlobUrl(url)) {
    return true;
  }
  return (
    AUDIO_EXTENSIONS.test(url) ||
    VIDEO_EXTENSIONS.test(url) ||
    HLS_EXTENSIONS.test(url) ||
    DASH_EXTENSIONS.test(url) ||
    FLV_EXTENSIONS.test(url)
  );
};
