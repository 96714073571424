import { useEvent } from 'utils/event-context';

const EventBackground = (props) => {
  const event = useEvent();
  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen py-12 sm:px-6 lg:px-8"
      style={{
        backgroundColor: event.bgColor ?? '#fff',
      }}
    >
      <div className="w-full max-w-sm m-3 overflow-hidden bg-white rounded-md shadow-2xl">
        {event.bgURL && (
          <img
            className="max-w-sm"
            alt=""
            src={event.bgURL}
            layout="responsive"
            width={1920}
            height={720}
          />
        )}
        <div className="p-3">{props.children}</div>
      </div>
    </div>
  );
};

export default EventBackground;
