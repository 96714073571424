import cn from 'classnames';

function hashCode(s) {
  let h = 0;
  for (let i = 0; i < (s?.length || 0); i++) h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  return Math.abs(h);
}

export default function Message({ date, from, message, messageRef }) {
  // const aDate = new Date(date.seconds * 1000);
  // const hours = aDate.toLocaleTimeString('es-AR');

  const colors = [
    'text-yellow-500',
    'text-indigo-500',
    'text-teal-500',
    'text-red-500',
    'text-blue-500',
    'text-orange-500',
    'text-green-500',
    'text-purple-500',
    'text-pink-500',
  ];

  return (
    <li className="p-1" ref={messageRef}>
      <p className="text-sm font-light text-gray-200 break-words">
        {/* <span className="mr-1" style={{ fontSize: '10px' }}>
          {date.toDate().toLocaleTimeString('es-AR')}
        </span> */}
        <span
          className={cn(
            'pr-2 font-semibold',
            colors[hashCode(from) % colors.length] || ''
          )}
        >
          {from}:
        </span>
        {message}
      </p>
    </li>
  );
}
