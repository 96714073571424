import cn from 'classnames';

const Button = ({ Icon, onClick, ...props }) => {
  return (
    <button
      className={cn(
        'p-1 transition duration-150 ease-in-out rounded-sm hover:bg-opacity-25 focus:outline-none hover:bg-white',
        props.className
      )}
      onClick={onClick}
    >
      <Icon className="w-6 h-6" />
    </button>
  );
};

export default Button;
