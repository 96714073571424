import { useDocument } from '@nandorojo/swr-firestore';
import { createContext, useContext } from 'react';
import { useParams } from './use-params';

const EventContext = createContext();

function EventContextProvider({ eventData, ...props }) {
  const { eventId } = useParams();
  const { data } = useDocument(`events/${eventId}`, { listen: true });

  return <EventContext.Provider value={data ?? eventData} {...props} />;
}

export const useEvent = () => {
  const event = useContext(EventContext);

  return { brandedMode: { enabled: false }, ...event };
};

export default EventContextProvider;
