import Button from 'components/common/Button';
import React from 'react';
import { CollapseChat } from '../icons/Icons';

export default function SidebarHeader({ onClose, withClose, user }) {
  return (
    <div className="flex flex-row justify-around flex-grow-0 flex-shrink-0 w-full py-2 text-center bg-gray-900 border-b border-gray-800 md:py-4">
      <header className="w-full px-2">
        <div className="flex items-start justify-between space-x-3">
          <div className="flex items-center h-7">
            <Button
              Icon={CollapseChat}
              onClick={onClose}
              className="hidden text-white sm:block"
            />
          </div>
          <h2 className="text-sm font-medium leading-7 text-white">Hola {user}</h2>
          <div>{/* <Button Icon={DotsIcon} className="text-white sm:block" /> */}</div>
        </div>
      </header>
    </div>
  );
}
