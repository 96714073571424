import { useReducer } from 'react';
import { useEvent } from 'utils/event-context';
import { useInterval } from 'utils/use-interval';

function ChatCompose({ sendMessage }) {
  const { chat } = useEvent();
  const [{ message, timeToNextMessage }, setChatState] = useReducer(
    (s, a) => ({ ...s, ...a }),
    { message: '', timeToNextMessage: 0 }
  );

  const enabled = timeToNextMessage <= 0;
  const text = message?.trim();

  useInterval(
    () => {
      setChatState({ timeToNextMessage: Math.max(timeToNextMessage - 1, 0) });
    },
    !enabled ? 1000 : null
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!text || !enabled) return;
    sendMessage(text);
    setChatState({
      message: '',
      timeToNextMessage: chat?.slowMode?.enabled ? chat.slowMode.seconds ?? 0 : 0,
    });
  };

  return (
    <form className="flex-grow-0 flex-shrink-0" onSubmit={handleSubmit}>
      <div className="flex flex-col p-2 bg-gray-900 border-t border-gray-800">
        <input
          type="text"
          placeholder="Envía un mensaje"
          className="w-full text-xs text-gray-200 transition duration-150 ease-in-out bg-gray-700 border-2 border-transparent focus:border-solid focus:border-purple-600 form-input sm:text-sm focus:outline-none focus:shadow-none"
          value={message}
          onChange={(e) => setChatState({ message: e.target.value })}
        />
        <div className="flex items-center justify-between pt-2">
          <div className="flex-grow text-xs text-white">
            {timeToNextMessage > 0 && (
              <span>Podrás enviar un mensaje en {timeToNextMessage}s</span>
            )}
          </div>
          <div className="flex-shrink-0">
            <button
              className="px-2 py-1 text-xs font-semibold text-white bg-purple-600 rounded-md outline-none focus:bg-purple-300 hover:bg-purple-700 disabled:bg-gray-600 disabled:cursor-not-allowed"
              disabled={!text || !enabled}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default ChatCompose;
