import { Fuego, FuegoProvider } from '@nandorojo/swr-firestore';
import * as Sentry from '@sentry/react';
import EventBackground from 'components/event/EventBackground';
import EventPlayer from 'components/event/EventPlayer';
import Links from 'components/event/Links';
import LoginForm from 'components/event/LoginForm';
import Head from 'next/head';
import { useCallback, useMemo, useReducer } from 'react';
import EventContextProvider, { useEvent } from 'utils/event-context';
import { getApp, getShopData, getShopDomain } from 'utils/firebase';

const KICK_MESSAGE = {
  title: 'Alguien ingreso con tu código',
  description: 'Solo una persona a la vez puede utilizar el mismo código.',
};
const LOGIN_ERROR_MESSAGE = {
  title: 'Código incorrecto',
  description: 'El código ingresado no es valido, revisalo e intenta nuevamente.',
};

const initialState = { message: null, loginData: null };
const reducer = (_, a) => ({ ...initialState, ...a });

const EventOpen = () => {
  const [{ loginData, message }, dispatch] = useReducer(reducer, initialState);

  const logout = useCallback(() => dispatch({ message: KICK_MESSAGE }), []);

  if (!loginData)
    return (
      <LoginForm
        onLogin={(loginData) => dispatch({ loginData })}
        onError={() => dispatch({ message: LOGIN_ERROR_MESSAGE })}
        message={message}
      />
    );

  return <EventPlayer loginData={loginData} onLogout={logout} />;
};

const EventComingSoon = () => {
  const { textPrev } = useEvent();
  return (
    <EventBackground>
      <div
        className="w-full py-5 text-center"
        dangerouslySetInnerHTML={{ __html: textPrev }}
      />
      <Links />
    </EventBackground>
  );
};

const EventEnded = () => {
  const { textPost } = useEvent();
  return (
    <EventBackground>
      <div
        className="w-full py-5 text-center"
        dangerouslySetInnerHTML={{ __html: textPost }}
      />
    </EventBackground>
  );
};

const EventComponent = () => {
  const { status, name } = useEvent();
  return (
    <>
      <Head>
        <title>{name}</title>
      </Head>
      {status === 'COMING_SOON' ? (
        <EventComingSoon />
      ) : status === 'ENDED' ? (
        <EventEnded />
      ) : (
        <EventOpen />
      )}
    </>
  );
};

const EventPage = ({ clientCredentials, eventData }) => {
  Sentry.setContext('event', { name: eventData.name });
  const fuego = useMemo(() => new Fuego(clientCredentials), [clientCredentials]);

  return (
    <FuegoProvider fuego={fuego}>
      <EventContextProvider eventData={eventData}>
        <EventComponent />
      </EventContextProvider>
    </FuegoProvider>
  );
};

const redirect404 = { redirect: { permanent: false, destination: '/404' } };

export async function getServerSideProps({ res, req, query }) {
  res.setHeader('Cache-Control', 'public, s-maxage=30, stale-while-revalidate=36000');

  try {
    const { clientCredentials } = await getShopData(getShopDomain(req));

    const { eventId } = query;
    const app = await getApp(req);
    const event = await app.firestore().collection('events').doc(eventId).get();
    if (!event.exists) {
      return redirect404;
    }
    const eventData = event.data();

    return { props: { clientCredentials, eventData } };
  } catch (e) {
    if (e.status === 404) {
      return redirect404;
    }
    throw e;
  }
}

export default EventPage;
