import { useEvent } from 'utils/event-context';

const Links = () => {
  const { shopLink, supportLink, supportLabel } = useEvent();

  if (!shopLink && !(supportLink && supportLabel)) return null;

  return (
    <div className="flex items-center px-2 pt-4 pb-2">
      {shopLink && (
        <div className="text-sm leading-5">
          <a
            href={shopLink}
            className="font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:outline-none focus:underline"
          >
            Comprar entrada
          </a>
        </div>
      )}
      {supportLink && supportLabel && (
        <div className="flex justify-end w-full text-sm leading-5">
          <a
            href={supportLink}
            className="font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:outline-none focus:underline"
          >
            {supportLabel}
          </a>
        </div>
      )}
    </div>
  );
};

export default Links;
