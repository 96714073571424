import cn from 'classnames';
import Chat from 'components/chat/Chat';
import { useEffect, useState } from 'react';
import { useEvent } from 'utils/event-context';
import { ChatIcon } from '../icons/Icons';
import SidebarHeader from './SidebarHeader';

const SidebarTab = ({ selected, Icon, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        'px-5 py-3 focus:outline-none',
        selected ? 'border-b-2 border-indigo-600' : ''
      )}
    >
      <Icon className="w-5 h-5 text-white sm:w-6 sm:h-6" />
    </button>
  );
};

function Sidebar({ open, setSidebarOpen, userDoc, tabs }) {
  const { sponsors, shopping } = useEvent();
  const [messages, setMessages] = useState([]);
  const [date, setDate] = useState();
  const [selectedTab, setSelectedTab] = useState(tabs[0].id);

  useEffect(() => {
    if (!tabs.find((t) => t.id === selectedTab)) {
      setSelectedTab(tabs[0].id);
    }
  }, [tabs, selectedTab]);

  useEffect(() => {
    if (selectedTab === 'chat') {
      const abortController = new AbortController();
      fetch('/api/now', { signal: abortController.signal })
        .then((r) => r.json())
        .then(({ now }) => setDate(new Date(Date.parse(now))))
        .catch(() => setDate(null));
      return () => abortController.abort();
    } else {
      setDate(null);
    }
  }, [open, selectedTab]);

  return (
    <>
      <div
        className={cn(
          'bg-gray-900 flex flex-col shadow flex-grow sm:flex-grow-0 sm:h-screen w-full h-full sm:w-1/3 lg:w-1/5 overflow-y-auto',
          !open ? ' sm:hidden' : ''
        )}
      >
        <SidebarHeader
          user={userDoc?.nickname || userDoc?.firstName}
          onClose={() => setSidebarOpen(false)}
        />
        <div className="flex flex-shrink-0 overflow-y-auto border-b border-gray-800">
          {tabs.map((t) => (
            <SidebarTab
              key={t.id}
              Icon={t.icon}
              selected={selectedTab === t.id}
              onClick={() => setSelectedTab(t.id)}
            />
          ))}
        </div>
        <div className="flex-grow h-full overflow-y-auto">
          {selectedTab === 'chat' && (
            <Chat
              setSidebarOpen={setSidebarOpen}
              user={userDoc}
              messages={messages}
              listenFrom={date}
              setMessages={setMessages}
            />
          )}
          {selectedTab === 'sponsors' && (
            <div
              className="p-8 overflow-y-auto text-white"
              dangerouslySetInnerHTML={{ __html: sponsors.content }}
            />
          )}
          {selectedTab === 'shopping' && (
            <iframe className="w-full h-full" src={shopping.url} title="Shopping" />
          )}
        </div>
      </div>

      <button
        className={cn(
          `bg-gray-800  rounded-l-md absolute top-1/2 transform -translate-y-1/2 right-0  focus:outline-none`,
          open ? 'hidden' : 'hidden sm:block'
        )}
        onClick={() => setSidebarOpen(true)}
      >
        <div className="p-3">
          <ChatIcon className="w-6 h-6 text-white sm:block" />
        </div>
      </button>
    </>
  );
}

export default Sidebar;
