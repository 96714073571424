import { fuego } from '@nandorojo/swr-firestore';
import { useEffect, useState } from 'react';

const useUser = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    fuego.auth().onAuthStateChanged((u) => {
      setUser(u ? { uid: u.uid } : null);
    });
  }, []);

  return user;
};

export default useUser;
